import React from 'react';
import translate from "../../i18n/translate";

const PaymentInformation = () => {
    return (
        <>
            <h5 className="card-heading">{translate('ordersummary.paymentInfo')}</h5>
            <div className="card">
                <div className="card-body">
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.paymentInfo.method')}</div>
                        <div className="col-sm-8 summaryValue">{translate('ordersummary.paymentInfo.methodValue')}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.paymentInfo.ocurrence')}</div>
                        <div className="col-sm-8 summaryValue">{translate('ordersummary.paymentInfo.ocurrenceValue')}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentInformation;