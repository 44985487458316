import React from 'react';
import Layout from '../components/shared/layout';
import OrderSummary from '../components/order-summary/orderSummary.component';
import FormLayoutComponent from "../components/form-layout/form-layout.component";

export default function OrderSummaryPage() {
    return (
        <Layout header={true} footer={true}>
            <FormLayoutComponent route="order-summary">
                <OrderSummary />
            </FormLayoutComponent>
        </Layout>
    )
}