import React from 'react';
import translate from "../../i18n/translate";
import deviceStore from '../../store/deviceStore';
import customerStore from '../../store/customerStore';

const DeviceDetails = () => {
    return (
        <>
            <h5 className="card-heading">{translate('ordersummary.insuredDevice')}</h5>
            <div className="card">
                <div className="card-body">
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.insuredDevice.brand')}</div>
                        <div className="col-sm-8 summaryValue">{deviceStore.deviceMake}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.insuredDevice.model')}</div>
                        <div className="col-sm-8 summaryValue">{deviceStore.deviceModel}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.insuredDevice.dateOfPurchase')}</div>
                        <div className="col-sm-8 summaryValue">{deviceStore.deviceActivationDate}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.insuredDevice.mobileNumber')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.customerPhoneNo}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.insuredDevice.imei')}</div>
                        <div className="col-sm-8 summaryValue">{deviceStore.deviceImei}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeviceDetails;